import React from 'react';
import { graphql } from 'gatsby';

import SEO from '@/components/seo';
import { Logos } from '@/components/homepage/v4/logos';
import Layout from '@/components/common/v5/layout';
import Hero from '@/components/case-studies/hero';
import List from '@/components/case-studies/list';
import CTAButtons from '@/components/case-studies/cta-buttons';
import AnimatedLogos from '@/components/Logos';

const CaseStudies = ({ data }) => {
  const heroData = {
    titleHighlighted: data.sanityCaseStudiesPage.titleHighlighted,
    titleNormal: data.sanityCaseStudiesPage.titleNormal,
    description: data.sanityCaseStudiesPage._rawDescription,
  };
  const logos = data?.sanityHomePage?.homepageHeroSectionFive?.customersDark;

  return (
    <Layout>
      <div className="relative h-full w-full bg-white">
        <Hero data={heroData} />
        <List
          items={data.allSanityCaseStudyPosts.nodes}
          cardLinkLabel={data.sanityCaseStudiesPage.cardLinkLabel}
        />
        <section className="mx-auto max-w-[90rem] px-5 py-10 lg:py-14">
          <CTAButtons
            title={data.sanityCaseStudiesPage.ctaTitle}
            primaryBtn={data.sanityCaseStudiesPage.ctaLinks[0]}
          />
        </section>
        <AnimatedLogos logos={logos} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query sanityCaseStudiesPage {
    sanityCaseStudiesPage {
      seo {
        metaTitle
        metaDescription
        image {
          asset {
            gatsbyImageData(width: 1280)
            url
            localFile(width: 1280) {
              publicURL
              childImageSharp {
                fixed(width: 1280) {
                  src
                  height
                  width
                }
              }
            }
          }
        }
      }

      titleHighlighted
      titleNormal
      _rawDescription(resolveReferences: { maxDepth: 10 })
      cardLinkLabel

      ctaTitle
      _rawCtaDescription(resolveReferences: { maxDepth: 10 })

      ctaLinks {
        ... on SanityExternalLink {
          _type
          label
          url
        }
        ... on SanityInternalLink {
          isCta
          label
          slug {
            current
          }
          _type
        }
      }
    }

    sanityHomePage {
      homepageHeroSectionFive {
        customersDark {
          asset {
            url
          }
        }
      }
    }

    allSanityCaseStudyPosts(sort: { fields: date, order: DESC }) {
      nodes {
        id
        title
        slug {
          current
        }
        clientLogo {
          asset {
            url
          }
        }
        mainImage {
          asset {
            gatsbyImageData(width: 930)
            url
          }
        }
        mainImageAlt
        standout
        isClickable
        heroColor
      }
    }
  }
`;

export default CaseStudies;

export const Head = ({ data }) => {
  const { seo } = data.sanityCaseStudiesPage;

  return (
    <SEO
      title={seo.metaTitle}
      description={seo.metaDescription}
      image={seo && seo.image ? seo.image.asset.localFile.publicURL : null}
      imageWidth={
        seo && seo.image
          ? seo.image.asset.localFile.childImageSharp.fixed.width
          : null
      }
      imageHeight={
        seo && seo.image
          ? seo.image.asset.localFile.childImageSharp.fixed.height
          : null
      }
    />
  );
};
